import {appPrefix, featureToggle, init} from '@ebexco/core/src/bootstrap';
import {conf} from '@ebexco/core/src/services/conf';
import {cssExt, jsExt} from '@ebexco/core/src/utils/extensions';
import {preFunnel} from '../src/pre-funnel';

featureToggle('convincely-hearing-choices');

const opts = {
  scripts: [
    conf.baseUrl + appPrefix + conf.app + jsExt,
  ],
  styles: [
    conf.baseUrl + appPrefix + conf.app + cssExt,
  ],
};

init(opts);
setTimeout(preFunnel);
